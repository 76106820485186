import { A, type RouteDefinition } from "@solidjs/router"
import { ShimmerButton } from "~/components/magicui/shimmer-button"

import { Captions, FileText, Gauge, HardDrive, Lightbulb, ScanSearch, TicketSlash } from "lucide-solid"
import Faq from "../components/ui/Faq"
import { Meta, Title } from "@solidjs/meta"

export const route = {
  load: () => {},
} satisfies RouteDefinition

export default function Home() {
  return (
    <>
      <Title>像编辑文档一样剪辑视频 - 碱基对</Title>
      <Meta
        name="description"
        content="搜索、删除、剪切、复制和粘贴视频片段，就像编辑文档一样简单。一站式视频编辑工具，提高您的视频创作效率。"
      />
      <Meta name="keywords" content="视频编辑,在线视频剪辑,文本编辑视频,简单视频处理" />
      <Meta property="og:title" content="碱基对 - 革新视频编辑方式" />
      <Meta property="og:description" content="像编辑文档一样轻松剪辑视频，一键去除停顿，快速导出精彩片段。" />
      <Meta property="og:type" content="website" />
      <Meta property="og:url" content="https://jianjidui.com" />

      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "WebApplication",
          name: "碱基对",
          description: "像编辑文档一样剪辑视频的在线工具",
          applicationCategory: "Multimedia",
          operatingSystem: "All",
          offers: {
            "@type": "Offer",
            price: "0",
            priceCurrency: "CNY",
          },
        })}
      </script>

      <section class="space-y-6 pb-8 pt-6 md:pb-12 md:pt-10 lg:py-32">
        <div class="container flex max-w-[64rem] flex-col items-center gap-4 text-center">
          <A href="https://x.com/youyidev" class="rounded-2xl bg-muted px-4 py-1.5 text-sm font-medium" target="_blank">
            Follow along on Twitter
          </A>
          <h1 class="font-heading text-3xl sm:text-5xl md:text-6xl lg:text-7xl">像编辑文档一样剪辑视频</h1>
          <p class="max-w-[42rem] leading-normal text-muted-foreground sm:text-xl sm:leading-8">
            搜索、删除、剪切、复制和粘贴视频片段，就像编辑文档一样简单。
          </p>
          <div class="space-x-4">
            <A href="/dashboard">
              <ShimmerButton class="shadow-2xl">
                <span class="whitespace-pre-wrap text-center text-sm font-medium leading-none tracking-tight text-white dark:from-white dark:to-slate-900/10 lg:text-lg">
                  开始使用
                </span>
              </ShimmerButton>
            </A>
          </div>
        </div>
      </section>
      <section id="features" class="container space-y-6 bg-slate-50 py-8 dark:bg-transparent md:py-12 lg:py-24">
        <div class="mx-auto flex max-w-[58rem] flex-col items-center space-y-4 text-center">
          <h2 class="font-heading text-3xl leading-[1.1] sm:text-3xl md:text-6xl">主要功能</h2>
          <p class="max-w-[85%] leading-normal text-muted-foreground sm:text-lg sm:leading-7"></p>
        </div>
        <div class="mx-auto grid justify-center gap-4 sm:grid-cols-2 md:max-w-[64rem] md:grid-cols-3">
          <div class="relative overflow-hidden rounded-lg border bg-background p-2">
            <div class="flex h-[180px] flex-col justify-between rounded-md p-6">
              <FileText />
              <div class="space-y-2">
                <h3 class="font-bold">基于文本的视频编辑</h3>
                <p class="min-h-[4rem] text-sm text-muted-foreground">
                  直接对文本进行删除、剪切、复制和粘贴，相当于对视频编辑。
                </p>
              </div>
            </div>
          </div>
          <div class="relative overflow-hidden rounded-lg border bg-background p-2">
            <div class="flex h-[180px] flex-col justify-between rounded-md p-6">
              <TicketSlash />
              <div class="space-y-2">
                <h3 class="font-bold">一键去除停顿</h3>
                <p class="min-h-[4rem] text-sm text-muted-foreground">
                  自动识别您视频或音频中的停顿，节省您的时间和精力。
                </p>
              </div>
            </div>
          </div>
          <div class="relative overflow-hidden rounded-lg border bg-background p-2">
            <div class="flex h-[180px] flex-col justify-between rounded-md p-6">
              <Lightbulb />
              <div class="space-y-2">
                <h3 class="font-bold">一键导出多个精彩片段</h3>
                <p class="min-h-[4rem] text-sm text-muted-foreground">
                  标记视频的多个精彩片段，一键导出，方便您的分享和发布。
                </p>
              </div>
            </div>
          </div>
          <div class="relative overflow-hidden rounded-lg border bg-background p-2">
            <div class="flex h-[180px] flex-col justify-between rounded-md p-6">
              <Captions />
              <div class="space-y-2">
                <h3 class="font-bold">导出字幕</h3>
                <p class="min-h-[4rem] text-sm text-muted-foreground">支持单独导出字幕。</p>
              </div>
            </div>
          </div>
          <div class="relative overflow-hidden rounded-lg border bg-background p-2">
            <div class="flex h-[180px] flex-col justify-between rounded-md p-6">
              <ScanSearch />
              <div class="space-y-2">
                <h3 class="font-bold">搜索和替换</h3>
                <p class="min-h-[4rem] text-sm text-muted-foreground">快速查找定位视频内容，一键修改替换文本。</p>
              </div>
            </div>
          </div>
          <div class="relative overflow-hidden rounded-lg border bg-background p-2">
            <div class="flex h-[180px] flex-col justify-between rounded-md p-6">
              <HardDrive />
              <div class="space-y-2">
                <h3 class="font-bold">视频保存在本地浏览器</h3>
                <p class="min-h-[4rem] text-sm text-muted-foreground">避免每次打开项目都从云端加载视频，打开迅速。</p>
              </div>
            </div>
          </div>
          <div class="relative overflow-hidden rounded-lg border bg-background p-2">
            <div class="flex h-[180px] flex-col justify-between rounded-md p-6">
              <Gauge />
              <div class="space-y-2">
                <h3 class="font-bold">倍速预览</h3>
                <p class="min-h-[4rem] text-sm text-muted-foreground">支持倍速预览</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Faq />
    </>
  )
}
