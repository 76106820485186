// db.ts
import Dexie, { Table } from "dexie"

import { TaskStatus } from "tencentcloud-sdk-nodejs-asr/tencentcloud/services/asr/v20190614/asr_models"

export type SpeechDoc = Pick<TaskStatus, "Result" | "ResultDetail">
export interface Project {
  id?: string
  title: string
  createdAt: Date
  speech2textTaskId?: number
  doc?: SpeechDoc
  words?: Word[]
  mainSource?: File
  mainSourceRealPath?: string
  secondSource?: File // 第二视角视频源文件
  secondSourceRealPath?: string // 第二视角视频本地路径
  secondSourceOffset?: number // 第二视角视频与主视角视频的时间偏移
  speech?: File
}
// {
//           "type": "text",
//           "value": "如",
//           "ts": 2.62,
//           "end_ts": 3.06,
//           "confidence": 0.97
//         }

export interface Word {
  highlight?: boolean
  type: "text" | "punct" | "silent"
  value: string
  /** 单位是 ms */
  start: number
  /** 单位是 ms */
  end: number
  adjustedEnd: number
  disabled?: boolean
}

export function doc2words(ResultDetail: SpeechDoc["ResultDetail"]) {
  let words: Word[] = []

  ResultDetail?.forEach((sentence) => {
    sentence?.Words?.forEach((word) => {
      let w: Word = {
        type: "text",
        value: word.Word,
        start: word.OffsetStartMs + (sentence.StartMs ?? 0),
        end: word.OffsetEndMs + (sentence.StartMs ?? 0),
        adjustedEnd: word.OffsetEndMs + (sentence.StartMs ?? 0),
        disabled: false,
      }
      let lastWord = words[words.length - 1]

      // 判断是否有静音片段
      if (w.start - (lastWord?.end ?? 0) > 0) {
        words.push({
          type: "silent",
          value: "...",
          start: lastWord?.end ?? 0,
          end: w.start,
          adjustedEnd: w.start,
          disabled: false,
        })
      }
      //如果和上一个word 的 start 和 end 一样，那么就是标点符号
      if (w.start == lastWord?.start && w.end == lastWord?.end) {
        w.type = "punct"
      }
      // 插入当前word
      words.push(w)
    })
  })

  return words
}

export class MySubClassedDexie extends Dexie {
  // 'friends' is added by dexie when declaring the stores()
  // We just tell the typing system this is the case
  projects!: Table<Project>

  constructor() {
    super("jianjidui")
    this.version(1).stores({
      projects: "id", // Primary key and indexed props
    })
  }
}

export const bdb = new MySubClassedDexie()
